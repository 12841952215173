﻿import '@popperjs/core';
import * as bootstrap from 'bootstrap';
import rg4js from 'raygun4js';
import "ts-polyfill";
import {ValidationService} from "aspnet-client-validation";

rg4js('enableCrashReporting', true);
rg4js('enablePulse', true);
rg4js('options', {
    ignoreAjaxAbort: true,
    ignoreAjaxError: true,
    captureMissingRequests: true
})
rg4js('apiKey', RAYGUN_APIKEY);

const validationService = new ValidationService();
validationService.bootstrap();

// Expose libraries on the window object
window.bootstrap = bootstrap;